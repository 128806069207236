@import './variables.scss';
@import './old-app.scss';
@import './simulator.scss';
@import './modal.scss';
@import './machine-setup.scss';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.box-section {
  border: black 1px;
  //background-color: grey;
}

.row {
  border: 0.2px solid rgba(199, 191, 191, 0.32);
}
