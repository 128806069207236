table {
  width: 100%;
  border: 1px solid black;
  table-layout: fixed;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #999;
    padding: 0.5rem;
    text-align: left;
  }
}
