.tape-grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat($num-visible-tape-cells, 1fr);
  width: 100%;
  padding: 10% 12.5%;

  .tape-cell {
    grid-row: 2 / span 1;
    border: teal 1px solid;
    color: teal;
  }

  .tape-head {
    grid-row: 1 / span 1;
    border: grey 1px solid;

    &-current {
      @extend .tape-head;
      border: blue 1px solid;

      color: blue;
    }

    &-previous {
      @extend .tape-head;
      color: grey;
    }
  }
}

@for $i from 1 through 20 {
  .single-col-#{$i} {
    grid-column: $i / span 1;
  }
}

.single-col-hidden {
  display: none;
}

.button {
  width: 100%;
  height: 100%;
  border: teal 1px solid;
  color: black;
}

.buttonContainer {
  padding: 0 12.5%;
  width: 75%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.setup-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  // grid-template-rows: 1fr 1fr;
  width: 75%;
  padding: 10% 12.5%;

  .setup-grid-row {
    border: teal 1px solid;
    color: teal;
  }
}
